import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      formFunctionCode: 'tpm_budget_audit_collect_form',
    };
  },
  methods: {
    // 表单渲染完成后回调
    formComplete() {
      if (this.formConfig.code === 'edit' || this.formConfig.code === 'view') {
        request.get('/tpm/tpmAuditCollectExampleController/query', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            const { result } = res;
            const data = result.pictureRespVos;
            let files = [];
            if (data && data.length) {
              files = data.map((item) => item.urlAddress);
            }
            this.setValue({ ...this.formConfig.row, ...result, file: files });
          }
        });
      }
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/tpm/tpmAuditCollectExampleController/save';
        const params = { ...formData };

        if (this.formConfig.code === 'edit') {
          url = '/tpm/tpmAuditCollectExampleController/update';
          params.id = this.formConfig.row.id;
        }

        // 图片处理
        if (params.file && params.file.length) {
          params.pictureRespVos = [];
          params.file.forEach((item) => {
            params.pictureRespVos.push({
              urlAddress: item,
            });
          });
        }

        delete params.file;

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
