import BasePage from '../../../../../components/table_page';
import Form from './form';

export default {
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
  },
  created() {
    this.getConfigList('tpm_budget_audit_collect_list');
  },
  methods: {
    modalClick({ val, row }) {
      const { code, name } = val;
      if (code === 'add' || code === 'edit' || code === 'view') {
        this.formConfig = {
          code,
          row,
        };
        this.modalConfig.title = name || '查看';

        this.openModal();
      }
    },
  },
};
